<template>
    <Toast />
    <Toast position="center" style="min-width:15vw" group="dialogtoast">
        <template #message="slotProps">
            <div class="flex flex-column">
                <div class="text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <h5>{{slotProps.message.detail}}</h5>
                </div>
                <div class="grid p-fluid">
                    <div class="col-12">
                        <Button class="p-button-success" label="确定" @click="dialogmsgClose" />
                    </div>
                </div>
            </div>
        </template>
    </Toast>
    <div class="card">
        <DataTable :value="processmonitorlist" :lazy="true" v-model:selection="selectedData" selectionMode="single"
            :scrollable="true" stripedRows :scrollHeight="sHeight" :loading="loading" scrollDirection="both"
            :paginator="true" :rows="pagesize" :virtualScrollerOptions="vsOptionFun()"
            :rowsPerPageOptions="[50, 100, 200, 500]" :totalRecords="totalRecords" :first="first"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="共{totalRecords}记录 当前页{first} 到 {last}" class="p-mt-3"
            @page="processmonitorPage($event)">
            <template #header>
                <div class="grid">
                    <div class="col-3">
                        <div class="field grid">
                            <label for="titleInput" class="col-fixed" style="width:100px">标题：</label>
                            <div class="col">
                                <InputText id='titleInput' v-model="searchInfo.title" type="text" />
                            </div>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="field grid">
                            <label for="flowDropdown" class="col-fixed" style="width:80px">流程：</label>
                            <div class="col">
                                <Dropdown id="flowDropdown" style="width:230px" v-model="searchInfo.flowid"
                                    :options="flowOptions" optionLabel="name" optionValue="code" showClear="true"
                                    :filter="true" />
                            </div>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="text-right">
                            <Button label="查询" icon="pi pi-search" @click="loadData(1)" class="ml-auto" />
                        </div>
                    </div>
                </div>
            </template>
            <Column field="title" header="标题" style="width: 30%" frozen>
                <template #body="slotProps">
                    <Button :label="slotProps.data.title" @click="opendetail(slotProps.data)" class="p-button-link" />
                </template>
            </Column>
            <Column field="flowname" header="流程" style="width: 20%"></Column>
            <Column field="flowstatus" header="流程状态" style="width: 20%"></Column>
            <Column field="flowstate" header="流程当前信息" style="width: 30%"></Column>
        </DataTable>
    </div>
    <Dialog header="流程信息" v-model:visible="displayPosition" :style="{width: '50vw'}" :position="position"
        :maximizable="true" :modal="true">
        <FlowHistory ref="flowhistory" :flowHistoryData="flowhistoryJson" :title="recordsubject" />
        <template #footer>
            <Button label="关闭" icon="pi pi-power-off" @click="closePosition" autofocus />
        </template>
    </Dialog>
    <Dialog :header="recordsubject" id="mainDialog" class="p-dialog-maximized" @maximize="selfmaximize"
        @unmaximize="selfmaximize" @hide="dialogClose" v-model:visible="displayMaximizable" :style="{width: '70vw'}"
        :maximizable="true" :modal="true">
        <p class="p-m-0">
            <NewUniversalPage ref="universalpage" :currentRow="currentRow" :childTableFooter="childTableFooter"
                :loadDataAfterMethod="loadDataAfter" :childExpandePageConfig="childExpandeConfig"
                :pageJsonConfig="pageJsonConfig" :isReadOnly="true" />
        </p>
        <template #footer>
            <div class="flex p-3 card">
                <Button label="流程信息" icon="pi pi-sitemap" class="p-button-raised p-button-text mr-2"
                    @click="openLeftPosition" />
                <Button label="关闭" icon="pi pi-power-off" class="ml-auto" @click="closeMaximizable" autofocus />
            </div>
        </template>
    </Dialog>
</template>
<script>
    import {
        ref,
    } from 'vue';
    import requests from '../../service/newaxios';
    import * as Base64 from 'js-base64';
    import CommonJs from '../../js/Common.js';
    import FactoryInfoBoxJs from '../../js/FactoryInfoBox.js';
    import QuotationBoxJs from '../../js/QuotationBox';
    import PurchaseBoxJs from '../../js/PurchaseBox';
    import ShipmentBoxJs from '../../js/ShipmentBox';
    import PutInStorageBoxJs from '../../js/PutInStorageBox';
    import ConfigService from '../../service/ConfigService';
    import NewUniversalPage from '../../components/NewUniversalPage.vue';
    import FlowHistory from '../../components/FlowHistory.vue';
    import MessageTip from '../../components/Message';
    export default {
        data() {
            return {
                selectedData: ref(),
                currentRow: null,
                flowhistoryJson: null,
                loading: false,
                displayMaximizable: false,
                displayPosition: false,
                position: 'bottomleft',
                recordsubject: '',
                totalRecords: -1,
                first: 0,
                searchInfo: {
                    title: '',
                    sysid: '',
                    flowid: '',
                },
                processmonitorlist: [],
                flowOptions: [],
                allSys: [],
                currencyDict: [],
                sHeight: '650px',
                childTableFooter: {},
                childExpandeConfig: {},
                pagesize: 50,
            }
        },
        configService: null,
        created() {
            this.pagesize = CommonJs.GetDefaultPageSize(this);
            this.configService = new ConfigService();
            MessageTip.initMsg();
            CommonJs.loadDictFromCache('Currency', 'dictionaryCurrency', this.currencyDict, this.setloadDictFromCache);
            this.loadDict();
            this.sHeight = (window.innerHeight - 300) + "px";
        },
        methods: {
            vsOptionFun() {
                return CommonJs.vsOptionFun(this.pagesize, this.totalRecords);
            },
            setloadDictFromCache(v_currencyDict) {
                this.currencyDict = v_currencyDict;
            },
            loadDict() {
                var dictlistwhere = {
                    pageindex: 1,
                    pagesize: 50,
                    conditions: [{
                        name: 'Categories',
                        value: 'sys',
                        operation: ''
                    }, {
                        name: 'Categories',
                        value: 'flow',
                        operation: ''
                    }]
                };
                var graphql = '{dictionarylist(where:' + JSON.stringify(dictlistwhere) +
                    '){name details {name code}}}';
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    graphql
                ).then(res => {
                    if (res.errcode == "0") {
                        var dataObj = JSON.parse(unescape(Base64.decode(res.data))).data;
                        console.log(dataObj);
                        for (var k = 0; k < dataObj.dictionarylist.length; k++) {
                            if (dataObj.dictionarylist[k].name == 'sys') {
                                this.allSys = dataObj.dictionarylist[k].details;
                            }
                            if (dataObj.dictionarylist[k].name == 'flow') {
                                this.flowOptions = dataObj.dictionarylist[k].details;
                            }
                        }
                        this.loadData(1);
                    } else {
                        MessageTip.warnmsg('字典数据加载失败');
                    }
                });
            },
            loadData(v_page) {
                this.loading = true;
                var processmonitorlistwhere = {
                    pageindex: v_page,
                    pagesize: this.pagesize,
                    conditions: [{
                        name: 'sysid',
                        value: '',
                        operation: '=',
                    }, {
                        name: 'title',
                        value: this.searchInfo.title,
                        operation: 'like',
                    }, {
                        name: 'searchflowid',
                        value: this.searchInfo.flowid,
                        operation: '=',
                    }]
                };
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{processmonitorlist(where:" + JSON.stringify(processmonitorlistwhere) +
                    "){id workflowid rootworkflowid flowid sysid title flowstatus flowstate flowname bindpage flowguid}}"
                ).then(res => {
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.processmonitorlist = jsonData.data.processmonitorlist;
                        console.log(this.processmonitorlist);
                        this.totalRecords = jsonData.page.allrecord;
                        this.first = this.pagesize * (jsonData.page.pageindex - 1);
                        this.loading = false;
                        console.log('totalRecords:' + this.totalRecords);
                    }
                });
            },
            processmonitorPage(event) {
                console.log('processmonitorPage...');
                console.log(event);
                var page = event.page;
                this.pagesize = event.rows;
                CommonJs.SaveDefaultPageSize(this.pagesize, this);
                this.loadData(page + 1);
            },
            onRecordClick() {

            },
            opendetail(v_row) {
                this.currentRow = v_row;
                this.recordsubject = v_row.subject;
                this.currentState = '流程信息-当前环节:' + v_row.statename;
                if (v_row.bindpage != '') {
                    this.initDetail(v_row.bindpage)
                } else {
                    MessageTip.warnmsg('类型名称不能为空');
                }
            },
            closeMaximizable() {
                console.log(this.$refs.universalpage);
                this.recordsubject = '';
                this.displayMaximizable = false;
            },
            openLeftPosition() {
                this.flowhistoryJson = this.$refs.universalpage.flowhistory;
                this.displayPosition = true;
            },
            closePosition() {
                this.displayPosition = false;
            },
            dialogmsgClose() {
                MessageTip.onClose();
            },
            selfmaximize() {
                CommonJs.selfmaximize();
            },
            initDetail(v_bindpage) {
                console.log('initDetail...' + v_bindpage);
                if (v_bindpage == 'purchase') {
                    this.configService.getpurchase(sessionStorage.getItem("appkey")).then(data => {
                        console.log(data);
                        var intData1 = PurchaseBoxJs.initDetail();
                        this.pageJsonConfig = data.page;
                        this.childExpandeConfig = data.page['region_3']['left']['element'][0][
                            'childExpandeConfig2'
                        ];
                        this.childTableFooter = intData1.childTableFooter;
                        this.displayMaximizable = true;
                    });
                }
                if (v_bindpage == 'inspectionmain') {
                    this.configService.getinspectionmain(sessionStorage.getItem("appkey")).then(data => {
                        console.log(data);
                        this.pageJsonConfig = data.page;
                        this.childExpandeConfig = data.page['region_3']['left']['element'][0][
                            'childExpandeConfig'
                        ];
                        this.childNewDataConfig = {};
                        this.dropOtherConfig = {};
                        this.childTableFooter = {};
                        this.displayMaximizable = true;
                    });
                }
                if (v_bindpage == 'shipment') {
                    this.configService.getshipment(sessionStorage.getItem("appkey")).then(data => {
                        console.log(data);
                        var intData2 = ShipmentBoxJs.initDetail();
                        this.pageJsonConfig = data.page;
                        this.childExpandeConfig = data.page['region_3']['left']['element'][0][
                            'childExpandeConfig'
                        ];
                        this.childTableFooter = intData2.childTableFooter;
                        this.displayMaximizable = true;
                    });
                }
                if (v_bindpage == 'quotation') {
                    this.configService.getquotation(sessionStorage.getItem("appkey")).then(data => {
                        console.log(data);
                        var intData3 = QuotationBoxJs.initDetail();
                        this.pageJsonConfig = data.page;
                        this.childExpandeConfig = data.page['region_3']['left']['element'][0][
                            'childExpandeConfig'
                        ];
                        this.childTableFooter = intData3.childTableFooter;
                        this.displayMaximizable = true;
                    });
                }
                if (v_bindpage == 'putinstorage') {
                    this.configService.getputinstorage(sessionStorage.getItem("appkey")).then(data => {
                        console.log(data);
                        var intData4 = PutInStorageBoxJs.initDetail();
                        this.pageJsonConfig = data.page;
                        this.childExpandeConfig = data.page['region_3']['left']['element'][0][
                            'childExpandeConfig'
                        ];
                        this.childTableFooter = intData4.childTableFooter;
                        this.displayMaximizable = true;
                    });
                }
                if (v_bindpage == 'factoryedithistory') {
                    this.configService.getfactoryEdit(sessionStorage.getItem("appkey")).then(data => {
                        console.log(data);
                        this.pageJsonConfig = data.page;
                        this.childExpandeConfig = {};
                        this.childTableFooter = {};
                        this.displayMaximizable = true;
                    });
                }
                if (v_bindpage == 'claimsettlement') {
                    this.configService.getclaimSettlement(sessionStorage.getItem("appkey")).then(data => {
                        console.log(data);
                        this.pageJsonConfig = data.page;
                        this.childExpandeConfig = {};
                        this.childTableFooter = {};
                        this.displayMaximizable = true;
                    });
                }
                if (v_bindpage == 'productsample') {
                    this.configService.getproductsample(sessionStorage.getItem("appkey")).then(data => {
                        console.log(data);
                        this.pageJsonConfig = data.productpage;
                        this.childExpandeConfig = {};
                        this.childNewDataConfig = {};
                        this.dropOtherConfig = {};
                        this.childTableFooter = {};
                        this.displayMaximizable = true;
                    });
                }
                if (v_bindpage == 'financialcollectionaccount') {
                    this.configService.getfinancialbox(sessionStorage.getItem("appkey")).then(data => {
                        console.log(data);
                        this.pageJsonConfig = data.collectionaccountpage;
                        this.childExpandeConfig = {};
                        this.childNewDataConfig = {};
                        this.dropOtherConfig = {};
                        this.childTableFooter = {};
                        this.displayMaximizable = true;
                    });
                }
                if (v_bindpage == 'financialexpensepay') {
                    this.configService.getfinancialexpensepay(sessionStorage.getItem("appkey")).then(data => {
                        console.log(data);
                        this.pageJsonConfig = data.page;
                        this.childExpandeConfig = {};
                        this.childNewDataConfig = {};
                        this.dropOtherConfig = {};
                        this.childTableFooter = {
                            paydetail: {
                                datano: '合计',
                                paymoney: 0,
                                invoiceamount: 0,
                            },
                        };
                        this.displayMaximizable = true;
                    });
                }
                if (v_bindpage == 'purchaseedit') {
                    this.configService.getpurchase(sessionStorage.getItem("appkey")).then(data => {
                        console.log(data);
                        this.pageJsonConfig = data.page2;
                        this.childExpandeConfig = data.page2['region_3']['left']['element'][0][
                            'childExpandeConfig2'
                        ];
                        this.childNewDataConfig = {};
                        this.dropOtherConfig = {};
                        this.childTableFooter = {
                            products: {
                                mpn: '合计',
                                quantity: 0,
                                allprice: 0,
                                grossweight: 0,
                                netweight: 0
                            }
                        };
                        this.displayMaximizable = true;
                    });
                }
            },
            loadDataAfter(v_mainelements, v_childelements, v_originalData, v_noshowelements,
                v_childExpandeConfig) {
                console.log(v_mainelements);
                console.log(v_childelements);
                console.log(v_originalData);
                console.log(v_noshowelements);
                if (this.currentRow.bindpage == 'shipment') {
                    ShipmentBoxJs.loadDataAfter(v_mainelements, v_childelements, v_originalData, this.currentRow,
                        this.currencyDict, v_childExpandeConfig);
                }
                if (this.currentRow.bindpage == 'quotation') {
                    QuotationBoxJs.loadDataAfter(v_mainelements, v_childelements, v_originalData, this.currentRow,
                        this.currencyDict, v_childExpandeConfig);
                }
                if (this.currentRow.bindpage == 'purchase') {
                    PurchaseBoxJs.loadDataAfter(v_mainelements, v_childelements, v_originalData, this.currentRow,
                        this.currencyDict, v_childExpandeConfig);
                }
                if (this.currentRow.bindpage == 'putinstorage') {
                    PutInStorageBoxJs.loadDataAfter(v_mainelements, v_childelements, v_originalData, this.currentRow,
                        this.currencyDict, v_childExpandeConfig);
                }
                if (this.currentRow.bindpage == 'factoryedithistory') {
                    FactoryInfoBoxJs.loadDataAfter(v_mainelements, v_childelements, v_originalData, this
                        .setrecordsubjectfun,
                        this.modifyelementsfun);
                }
            },
            dialogClose() {}
        },
        components: {
            NewUniversalPage,
            FlowHistory,
        }
    }
</script>